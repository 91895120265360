import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async getAll(travelUuid, profileUuid) {
    let result = await axios.get(`${API_ROUTES.sale.get}/${travelUuid}/${profileUuid}`);

    return result;
  },
  
  async save(id, data) {
    let result = await axios.post(`${API_ROUTES.sale.save}`, data);

    return result;
  },

  async update(id, data) {
    let result = await axios.put(`${API_ROUTES.sale.update}/${id}`, data);

    return result;
  },

  async moveSale(data) {
    let result = await axios.put(`${API_ROUTES.moveSale.update}`, data);

    return result;
  },

  async moveSaleEnteprise(data) {
    let result = await axios.put(`${API_ROUTES.moveSaleEnterprise.update}`, data);

    return result;
  },

  async moveSaleEnterpriseNotExist(data) {
    let result = await axios.put(`${API_ROUTES.moveSaleEnterpriseNotExist.update}`, data);

    return result;
  },

  async generarReport(uuid, numberSarper) {
    let result = await axios.get(`${API_ROUTES.sale.report}/${uuid}/${numberSarper}`);

    return result;
  },

  async generarReportPilot(uuid, numberSarper) {
    let result = await axios.get(`${API_ROUTES.sale.reportPilot}/${uuid}/${numberSarper}`);

    return result;
  },

  async delete(id) {
    let result = await axios.delete(`${API_ROUTES.sale.delete}/${id}`);

    return result;
  },
}